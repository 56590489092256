import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { useLoading } from "../Static/LoadingContext";

const LoginPage = (props) => {
  const initialValues = { username: "", password: "" };
  const [signinValues, setSigninValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const location = useLocation();
  const message = location?.state?.message;
  const success = props.success;
  const verificationmessage = props.verificationmessage;

  // Toast message effect
  useEffect(() => {
    if (message) {
      setToastMessage(message);
      setToastVariant("success");
      setShowToast(true);
    }
    if (success === true) {
      setToastMessage(verificationmessage);
      setToastVariant("success");
      setShowToast(true);
    }
  }, [message, success, verificationmessage]);

  // Authentication token storage
  const storeAuthToken = (token, userType, children, firstName) => {
    return new Promise((resolve, reject) => {
      if (token && userType) {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        localStorage.setItem('authToken', token);
        localStorage.setItem('userType', userType);
        localStorage.setItem('firstName', firstName);
        if (userType === "parent") {
          localStorage.setItem('children', JSON.stringify(children));
        }
        resolve();
      } else {
        delete axios.defaults.headers.common['Authorization'];
        localStorage.removeItem('authToken');
      }
    });
  };

  // Form handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "username" ? value.toLowerCase() : value;
    setSigninValues((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    setFormErrors((prevState) => {
      const errors = { ...prevState };
      delete errors[name];
      return errors;
    });
  };

  const handleSubmit = (event) => {
    const errors = validate(signinValues);
    setFormErrors(errors);
    setIsSubmit(true);
  };

  // Submit effect for API call
  useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      axios.post(process.env.REACT_APP_BACKEND_API_URL + "/login/", signinValues)
        .then((response) => {
          const authToken = response.data.token;
          const userType = response.data.userType;
          const children = response.data.children;
          const firstName = response.data.firstName;
          storeAuthToken(authToken, userType, children, firstName).then(() => {
            if (userType === "child") {
              navigate("/ppstartpage");
            } else if (userType === "parent") {
              navigate("/home", { state: { userType, children } });
            } else if (userType === "qbAdmin" || userType === "super admin") {
              navigate("/addquestions");
            } else if (userType === "qbDeveloper") {
              navigate("/mathsquestions");
            }
          });
        })
        .catch((error) => {
          if (error.response == undefined) {
            setErrorMessage("We are currently facing technical issue. Please try again later. (Error code " + error.code + ")");
          }
          else if (error.response.status === 401) {
            setErrorMessage(error.response.data.detail);
          } else if (error.response.status === 500) {
            setErrorMessage("Something went Wrong. Please try again.");
          }
          else{
            setErrorMessage(error.response.data);
          }
        })
        .finally(() => {
          setIsSubmit(false);
          setIsLoading(false);
        });
    } else {
      setIsSubmit(false);
    }
  }, [formErrors, isSubmit, signinValues]);

  // Check existing auth token
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userType = localStorage.getItem('userType');
    const children = JSON.parse(localStorage.getItem("children"));
    const firstName = localStorage.getItem("firstName");
    if (authToken && userType) {
      storeAuthToken(authToken, userType, children, firstName).then(() => {
        if (userType === "child") {
          navigate("/ppstartpage");
        } else if (userType === "parent") {
          navigate("/home", { state: { userType, children } });
        } else if (userType === "qbAdmin" || userType === "super admin") {
          navigate("/addquestions");
        }
        else if (userType === "qbDeveloper") {
          navigate("/mathsquestions");
        }
      });
    }
  }, []);

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is required!";
    }
    if (values.username === "") {
      errors.username = "Username cannot be empty";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 8 characters or above";
    }
    return errors;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Login Form Section */}
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-md mx-auto">
          <div className="bg-white rounded-xl shadow-lg p-8">
            <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
              Sign In
            </h2>
            {errorMessage && (
              <div className="bg-red-50 text-red-700 p-4 rounded-lg mb-6">
                {errorMessage}
              </div>
            )}
            <form className="space-y-6">
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={signinValues.username}
                  onChange={handleChange}
                  onKeyDown={handleEnterKeyPress}
                  className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500 ${
                    formErrors.username ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {formErrors.username && (
                  <p className="mt-1 text-sm text-red-600">{formErrors.username}</p>
                )}
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={signinValues.password}
                  onChange={handleChange}
                  onKeyDown={handleEnterKeyPress}
                  className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500 ${
                    formErrors.password ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {formErrors.password && (
                  <p className="mt-1 text-sm text-red-600">{formErrors.password}</p>
                )}
              </div>

              <button
                type="button"
                onClick={handleSubmit}
                className="w-full bg-violet-600 text-white py-2 px-4 rounded-lg hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-colors duration-200"
              >
                Sign In
              </button>
            </form>

            <div className="mt-6 text-center">
              <Link to="/forgotpassword" className="text-violet-600 hover:text-violet-800">
                Forgot password?
              </Link>
            </div>

            <div className="mt-6 border-t border-gray-200 pt-6 text-center">
              <p className="text-gray-600">
                Don't have an account?{' '}
                <Link to="/parentguardiansignup" className="text-violet-600 hover:text-violet-800 font-medium">
                  Sign Up Here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Toast Notification */}
      {showToast && (
        <div className="fixed top-4 right-4 z-50">
          <div className={`rounded-lg p-4 ${
            toastVariant === 'success' ? 'bg-green-500' : 'bg-blue-500'
          } text-white shadow-lg`}>
            {toastMessage}
          </div>
        </div>
      )}

      {/* Footer */}
      {/* <div className="bg-gray-900 text-gray-400">
        <div className="container mx-auto py-8 px-4">
          <div className="flex justify-center items-center">
            <p className="text-sm">
              © 2025 All Rights Reserved, Practice Papers®
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default LoginPage;