import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { Button, Card, Row,Col } from 'react-bootstrap'; // import the Button and Card components
import Home from './Home';
import Alert from 'react-bootstrap/Alert';
import LoginPage from './LoginPage';


function ActivationPage(props) {
    const { success } = props;
  
    if (success === true) {
      return (
        <LoginPage success={success} verificationmessage={props.message}></LoginPage>
      );
    } else {
      return (
        <>
            <Alert variant='danger' >{props.message}</Alert>
            {props.message != "Invalid activation link." && (
              <>
              <Row>
                <Col>
                <Link to="/">Sign In here</Link>
                </Col>
                <Col>
                <Link to="/resetpassword">Reset Password</Link>
                </Col>
                </Row>
              </>
            )}
         </>
      );
    }
  }
  
function ActivationRedirect(props) {
    const { id } = useParams();
    const [success, setSuccess] = React.useState(undefined);
    const [message, setMessage] = React.useState(undefined);

    useEffect(() => {
        const csrftoken = Cookies.get("csrftoken");
        axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
        axios.get(process.env.REACT_APP_BACKEND_API_URL + `/activate/${id}/`)
            .then(response => {
                // console.log(response);
                if (response.status === 200) {
                    setSuccess(true);
                    setMessage(response.data.message)
                } else {
                    setSuccess(false);
                }
            })
            .catch(error => {
                console.error(error.response.data.message);
                setSuccess(false);
                setMessage(error.response.data.message)
            });
    }, [id]);
    if (success === undefined) {
        return <h1>Loading...</h1>;
    }

    return <ActivationPage success={success} message={message} />;
}

export { ActivationPage, ActivationRedirect };
