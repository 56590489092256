import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { useLoading } from "../Static/LoadingContext";
import studentStudyingImage from '../../images/student_studying.jpg';
import studentsStudyingImage from '../../images/students_studying.jpg';
import studentsJoy from '../../images/students_joy.jpg';

const LandingPage = (props) => {
  // Keeping all the existing state and handlers
  const initialValues = { username: "", password: "" };
  const [signinValues, setSigninValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const location = useLocation();
  const message = location?.state?.message;
  const success = props.success;
  const verificationmessage = props.verificationmessage;
  const [showLoginModal, setShowLoginModal] = useState(false);


  // Keeping all existing useEffect hooks and handlers
  useEffect(() => {
    if (message) {
      setToastMessage(message);
      setToastVariant("success");
      setShowToast(true);
    }
    if (success === true) {
      setToastMessage(verificationmessage);
      setToastVariant("success");
      setShowToast(true);
    }
  }, [message, success, verificationmessage]);

  // Keeping all existing handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "username" ? value.toLowerCase() : value;
    setSigninValues((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    setFormErrors((prevState) => {
      const errors = { ...prevState };
      delete errors[name];
      return errors;
    });
  };

  const handleSubmit = (event) => {
    const errors = validate(signinValues);
    setFormErrors(errors);
    setIsSubmit(true);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is required!";
    }
    if (values.username === "") {
      errors.username = "Username cannot be empty";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 8 characters or above";
    }
    return errors;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-[#FFF5F2] py-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row items-center justify-between">
                  <div className="lg:w-1/2 mb-8 lg:mb-0 pr-8">
                    <h1 className="text-5xl font-bold text-gray-900 mb-6">
                    Excellence in 11+ Exam Preparation
                    </h1>
                    <p className="text-xl text-gray-600 mb-8">
                    Enhance your child's learning journey with our comprehensive practice tests. Access thousands of expertly designed questions for GL and CEM style UK 11+ exams.
                    </p>
                    <Link 
                    to="/parentguardiansignup"
                    className="inline-block bg-violet-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-violet-700 transition-colors duration-200"
                    >
                    Get started
                    </Link>
                  </div>

                      <div className="lg:w-1/2 grid grid-cols-2 gap-4">
                      <img src={studentStudyingImage} alt="Student studying" className="rounded-lg shadow-md" />
                      <img src={studentsStudyingImage} alt="Practice test" className="rounded-lg shadow-md mt-8" />
                      <img src={studentsJoy} alt="Learning materials" className="rounded-lg shadow-md" />
                      {/* <img src="/api/placeholder/400/300" alt="Results dashboard" className="rounded-lg shadow-md mt-8" /> */}
                  </div>
                  </div>
                </div>
                </div>
                    {/* Feature Section */}
      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row items-center gap-12">
            {/* Left side - Interactive Demo */}
            <div className="lg:w-1/2">
              <div className="bg-gray-50 rounded-xl p-4 shadow-lg">
                <div className="bg-white rounded-lg shadow-sm p-3">
                  <div className="flex items-center gap-2 mb-3">
                    <div className="flex gap-1">
                      <div className="w-3 h-3 rounded-full bg-red-400"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                      <div className="w-3 h-3 rounded-full bg-green-400"></div>
                    </div>
                  </div>
                  <div className="flex gap-2 mb-4">
                    <div className="flex-1 h-8 bg-gray-100 rounded flex items-center px-3">
                      <span className="text-sm text-gray-400">Search practice tests...</span>
                    </div>
                    <button className="px-4 py-1 bg-violet-100 text-violet-600 rounded text-sm">Search</button>
                  </div>
                  <div className="grid grid-cols-3 gap-3">
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Right side - Feature Description */}
            <div className="lg:w-1/2">
              <h2 className="text-4xl font-bold text-gray-900 mb-6">
                Comprehensive Test Library
              </h2>
              <p className="text-xl text-gray-600">
                Access our extensive collection of practice tests designed specifically for 11+ exam preparation. 
                Search by subject area, difficulty level, or exam board to find the perfect practice materials 
                for your child's learning journey. With thousands of questions available, you'll never run out 
                of quality practice content.
              </p>
            </div>
          </div>
        </div>
      </div>

       {/* Second Feature Section */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row-reverse items-center gap-12">
            {/* Right side - Interactive Demo */}
            <div className="lg:w-1/2">
              <div className="bg-gray-50 rounded-xl p-4 shadow-lg">
                <div className="bg-white rounded-lg shadow-sm p-3">
                  <div className="flex items-center gap-2 mb-3">
                    <div className="flex gap-1">
                      <div className="w-3 h-3 rounded-full bg-red-400"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                      <div className="w-3 h-3 rounded-full bg-green-400"></div>
                    </div>
                  </div>
                  <div className="flex gap-2 mb-4">
                    <div className="flex-1 h-8 bg-gray-100 rounded flex items-center px-3">
                      <span className="text-sm text-gray-400">Search practice tests...</span>
                    </div>
                    <button className="px-4 py-1 bg-violet-100 text-violet-600 rounded text-sm">Search</button>
                  </div>
                  <div className="grid grid-cols-3 gap-3">
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Left side - Feature Description */}
            <div className="lg:w-1/2">
              <h2 className="text-4xl font-bold text-gray-900 mb-6">
                Instant Marking System
              </h2>
              <p className="text-xl text-gray-600">
                Receive real-time feedback and detailed explanations for incorrect answers, 
                helping students learn from their mistakes. Our intelligent marking system 
                provides instant insights and personalized guidance to improve understanding 
                and boost confidence.
              </p>
            </div>
          </div>
        </div>
      </div>

                      {/* third Feature Section */}
                      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row items-center gap-12">
            {/* Left side - Interactive Demo */}
            <div className="lg:w-1/2">
              <div className="bg-gray-50 rounded-xl p-4 shadow-lg">
                <div className="bg-white rounded-lg shadow-sm p-3">
                  <div className="flex items-center gap-2 mb-3">
                    <div className="flex gap-1">
                      <div className="w-3 h-3 rounded-full bg-red-400"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                      <div className="w-3 h-3 rounded-full bg-green-400"></div>
                    </div>
                  </div>
                  <div className="flex gap-2 mb-4">
                    <div className="flex-1 h-8 bg-gray-100 rounded flex items-center px-3">
                      <span className="text-sm text-gray-400">Search practice tests...</span>
                    </div>
                    <button className="px-4 py-1 bg-violet-100 text-violet-600 rounded text-sm">Search</button>
                  </div>
                  <div className="grid grid-cols-3 gap-3">
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Right side - Feature Description */}
            <div className="lg:w-1/2">
              <h2 className="text-4xl font-bold text-gray-900 mb-6">
              Progress Tracking
              </h2>
              <p className="text-xl text-gray-600">
              Monitor your child's performance, identify areas for improvement, and build confidence as you work through our practice tests.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Fourth Feature Section */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row-reverse items-center gap-12">
            {/* Right side - Interactive Demo */}
            <div className="lg:w-1/2">
              <div className="bg-gray-50 rounded-xl p-4 shadow-lg">
                <div className="bg-white rounded-lg shadow-sm p-3">
                  <div className="flex items-center gap-2 mb-3">
                    <div className="flex gap-1">
                      <div className="w-3 h-3 rounded-full bg-red-400"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                      <div className="w-3 h-3 rounded-full bg-green-400"></div>
                    </div>
                  </div>
                  <div className="flex gap-2 mb-4">
                    <div className="flex-1 h-8 bg-gray-100 rounded flex items-center px-3">
                      <span className="text-sm text-gray-400">Search practice tests...</span>
                    </div>
                    <button className="px-4 py-1 bg-violet-100 text-violet-600 rounded text-sm">Search</button>
                  </div>
                  <div className="grid grid-cols-3 gap-3">
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                    <div className="aspect-video bg-gray-100 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Left side - Feature Description */}
            <div className="lg:w-1/2">
              <h2 className="text-4xl font-bold text-gray-900 mb-6">
              Customizable practice sessions
              </h2>
              <p className="text-xl text-gray-600">
              Focus on specific subjects or question types, tailoring your child's practice experience to suit their specific needs.
              </p>
            </div>
          </div>
        </div>
      </div>

                {/* Sign In Section */}
      {/* Login Modal */}
<div 
  className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center ${showLoginModal ? '' : 'hidden'}`}
  onClick={() => setShowLoginModal(false)}
>
  <div 
    className="bg-white rounded-xl shadow-lg max-w-md w-full mx-4"
    onClick={e => e.stopPropagation()}
  >
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-900">Sign In</h2>
        <button 
          onClick={() => setShowLoginModal(false)}
          className="text-gray-400 hover:text-gray-500"
        >
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      {errorMessage && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg mb-6">
          {errorMessage}
        </div>
      )}

      <form className="space-y-4">
        <div>
          <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
            Username
          </label>
          <input
            type="text"
            name="username"
            id="username"
            value={signinValues.username}
            onChange={handleChange}
            onKeyDown={handleEnterKeyPress}
            className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500 ${
              formErrors.username ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {formErrors.username && (
            <p className="mt-1 text-sm text-red-600">{formErrors.username}</p>
          )}
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            value={signinValues.password}
            onChange={handleChange}
            onKeyDown={handleEnterKeyPress}
            className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500 ${
              formErrors.password ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {formErrors.password && (
            <p className="mt-1 text-sm text-red-600">{formErrors.password}</p>
          )}
        </div>

        <button
          type="button"
          onClick={handleSubmit}
          className="w-full bg-violet-600 text-white py-2 px-4 rounded-lg hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-colors duration-200"
        >
          Sign In
        </button>
      </form>

      <div className="mt-4 text-center">
        <Link to="/forgotpassword" className="text-violet-600 hover:text-violet-800 text-sm">
          Forgot password?
        </Link>
      </div>
    </div>
  </div>
</div>

      {/* Toast Notification */}
      {showToast && (
        <div className="fixed top-4 right-4 z-50">
          <div className={`rounded-lg p-4 ${
            toastVariant === 'success' ? 'bg-green-500' : 'bg-blue-500'
          } text-white shadow-lg`}>
            {toastMessage}
          </div>
        </div>
      )}

<div className="bg-gray-900 text-gray-400 mt-auto">
  <div className="container mx-auto py-8 px-4">
    <div className="flex justify-center items-center">
      <p className="text-sm">
        © 2025 All Rights Reserved, Practice Papers®
      </p>
    </div>
  </div>
</div>
    </div>
  );
};

export default LandingPage;